import React from 'react'
import { FormattedMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudyOnline4baby.module.css";

// import parcelToGoImg from "../../assets/parcelToGo-deodorants.jpeg"
import parcelToGoImg from "../../assets/p2g-image-1.png"
import parcelToGo2Img from "../../assets/p2g-image-2.png"
import tyvisoLogo from "../../assets/simple-logo.png"

import TestimonoalImgOne from "../../assets/sian.jpeg"
import TestimonoalImgTwo from "../../assets/adrian.jpg"

const testimonials = [
  {
    id: 'parcelToGo',
    img: TestimonoalImgOne,
    alt: 'Sian Wells, Digital Marketing Manager',
    text: '"From Day 1 I couldn’t say a better word about Tyviso’s support and flexibility. The team worked with us on finding a solution that fit our site, remained close while we tested, and were a constant support across optimisations and data analysis. I’m looking forward to a continued future with the team, and I’m excited to see what more we can achieve with Tyviso."',
    author: 'Sian Wells, Digital Marketing Manager'
  },
  {
    id: 'tyviso',
    img: TestimonoalImgTwo,
    alt: 'Adrian Vella, CEO, Tyviso',
    text: '"This campaign was a masterclass in two teams working together to deliver the results. P2G is a unique customer journey. Measured A/B testing and working together played a massive part in providing great customer value."',
    author: 'Adrian Vella, CEO, Tyviso'
  }
]

const resultList = [
  {
    normal: 'increase in basket completion rate',
    bold: '1.8% '
  },
  {
    pre: 'Average order value increased by ',
    bold: '29.23 % '
  },
  {
    normal: 'increase in revenue per user',
    bold: '17.2% '
  },
]

function CaseStudyOnline4baby({ title, subTitle }) {
  return (
    <div className={styles.CaseStudy}>
      <h2 className={styles.CaseStudyTitle}>
        <FormattedMessage id={title} />
      </h2>

      <div className={styles.CaseStudyFeaturedWrapper}>
        <img
          className={styles.CaseStudyFeaturedImg}
          src={parcelToGoImg}
          alt="parcelToGo"
        />
        <div className={styles.CaseStudyFeaturedContent}>
          <h3 className={styles.CaseStudySubTitle}>
            <FormattedMessage id={subTitle} />
          </h3>
          <p className={styles.CaseStudyText}>
            <FormattedMessage id="parcelToGoCaseFeaturedTextOne" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="parcelToGoCaseObjectiveTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="parcelToGoCaseObjectiveDescription" />
          </p>
          <ol>
            <li>
              <FormattedMessage id="parcelToGoCaseObjectiveItemOne" />
            </li>
            <li>
              <FormattedMessage id="parcelToGoCaseObjectiveItemTwo" />
            </li>
            <li>
              <FormattedMessage id="parcelToGoCaseObjectiveItemThree" />
            </li>
          </ol>
        </div>
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="parcelToGoCaseApproachTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="parcelToGoCaseApproachItemOne" />
          </p>
          <p>
            <FormattedMessage id="parcelToGoCaseApproachItemTwo" />
          </p>
          <p>
            <FormattedMessage id="parcelToGoCaseApproachItemThree" />
          </p>
        </div>
      </div>

      <div className={styles.CaseStudySimply}>
        <img src={parcelToGo2Img} alt="" />
      </div>

      <div className={styles.CaseStudyItem}>
        <div className={styles.CaseStudyDescCol}>
          <h3>
            <img
              className={styles.CaseStudyTyviso}
              src={tyvisoLogo}
              alt="Tyviso"
            />
            <FormattedMessage id="parcelToGoCaseResultsTitle" />
          </h3>
        </div>
        <div className={styles.CaseStudyTextCol}>
          <p>
            <FormattedMessage id="parcelToGoCaseResultsItemOne" />
          </p>
          <ul>
            {
              resultList.map(({pre, bold, normal}, idx) => (
                <li key={`result-list--${idx}`}>
                  {pre}<strong>{bold}</strong>{normal}
                </li>
              ))
            }
          </ul>
        </div>
      </div>

      <div className={styles.CaseStudyTestimonialWrapper}>
        {
          testimonials.map(item => (
            <div className={styles.CaseStudyTestimonial}>
              <div className={styles.CaseStudyTestimonialImage}>
                <img
                  src={item.img}
                  alt={item.alt}
                />
              </div>
              <div className={styles.CaseStudyTestimonialContent}>
                <p>{item.text}</p>
                <h3>{item.author}</h3>
              </div>
          </div>
          ))
        }
      </div>
    </div>
  );
};

export default CaseStudyOnline4baby;